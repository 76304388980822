<template>
  <div ref="form">
    <v-card>
      <v-card-title> {{ form.id > 0 ? "修改" : "新增" }}资产 </v-card-title>
      <v-card-text>
        <div class="item">
          <v-text-field label="编号" v-model="form.identifier"></v-text-field>
          <!-- <v-spacer></v-spacer> -->
          <v-btn class="ma-2" @click="gend(0)" icon color="indigo">
            <v-icon>mdi-chart-arc</v-icon>
          </v-btn>
          <!-- <v-btn
            :loading="loading"
            color="primary"
            class="ml-2 btnW"
            @click="gend(0)"
          >
            生成编号
          </v-btn> -->
        </div>
        <div class="item">
          <v-text-field
            label="域名"
            v-model="domainF"
            :suffix="domainE"
            append-outer-icon="mdi-pencil-lock"
            @click:append-outer="dialog = true"
          ></v-text-field>
          <!-- <v-btn
            :loading="loading"
            class="ml-2 btnW"
            color="primary"
            @click="gend(1)"
          >
            生成域名前缀
          </v-btn> -->
          <v-btn class="ma-2" @click="gend(1)" icon color="indigo">
            <v-icon>mdi-chart-arc</v-icon>
          </v-btn>
        </div>
        <v-text-field label="资产名称" v-model="form.name"></v-text-field>
        <v-text-field label="IPV4地址" v-model="form.ip_addr_v4"></v-text-field>
        <v-text-field label="协议" v-model="form.protocol"></v-text-field>
        <v-text-field label="端口" v-model="form.port"></v-text-field>
        <v-btn
          :loading="loading"
          depressed
          color="primary"
          block
          @click="submit"
        >
          提交
        </v-btn>
        <v-btn depressed class="mt-1" block @click="cancel"> 取消 </v-btn>
      </v-card-text>
    </v-card>
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="text-h7 lighten-2"> 修改域名结尾 </v-card-title>
        <v-text-field
          style="padding-left: 20px; padding-right: 20px"
          label="域名结尾"
          v-model="domainE"
        ></v-text-field>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="dialog = false"> 确认</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "",
  props: [""],
  components: {},
  data() {
    return {
      loading: false,
      form: {
        domain: "",
        name: "",
        identifier: "",
        ip_addr_v4: "",
        id: 0,
        protocol: "",
        port: "",
      },
      domainF: "",
      domainE: ".ipv6plus.cn",
      dialog: false,
    };
  },
  methods: {
    getOne() {
      let id = sessionStorage.getItem("listId");
      if (id && id > 0) {
        this.$axios
          .get(`/trunkserver/assets/entity/${id}/`)
          .then((res) => {
            if (res.data && res.data.id > 0) {
              this.form = res.data;
              if (this.form.domain && this.form.domain.split(".").length > 0) {
                this.domainF = this.form.domain.split(".")[0];
                this.domainE = this.form.domain.substring(this.domainF.length);
                console.log(this.domainF, this.domainE);
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    submit() {
      this.loading = true;
      this.form.domain = this.domainF + this.domainE;
      if (this.form.id && this.form.id > 0) {
        //修改put
        this.$axios
          .put(`/trunkserver/assets/entity/${this.form.id}/`, this.form)
          .then((res) => {
            if (res.data && res.data.id > 0) {
              this.$message.success("此操作已成功!");
              sessionStorage.setItem("listId", 0);
              this.$router.push({
                path: "/domain/list",
              });
            }
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            console.log(err);
          });
      } else {
        console.log(this.form);
        this.$axios
          .post("/trunkserver/assets/entity/", this.form)
          .then((res) => {
            if (res.data && res.data.id > 0) {
              this.$message.success("此操作已成功!");
              sessionStorage.setItem("listId", 0);
              this.$router.push({
                path: "/domain/list",
              });
            }
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            console.log(err);
          });
      }
    },
    cancel() {
      this.disabled = false;
      sessionStorage.setItem("listId", 0);
      this.$router.push({
        path: "/domain/list",
      });
    },
    gend(index) {
      const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890";
      const maxPos = chars.length;
      let str = "";
      let length = index == 0 ? 15 : 20;
      for (let i = 0; i < length; i++) {
        str += chars.charAt(Math.floor(Math.random() * maxPos));
      }
      if (index == 0) {
        this.form.identifier = str;
      } else {
        this.domainF = str.toLowerCase();
      }
    },
  },
  created() {
    this.getOne();
  },
};
</script>

<style scoped>
.item {
  display: flex;
  flex-direction: row;
}
.btnW {
  width: 10%;
}
</style>
